import React from 'react'
import Img from 'gatsby-image'
import { rhythm } from '../utils/typography'
import { Link } from 'gatsby'

export default function CardPost({className, img, imgSizes, date, link, title, description}){
    return(
        <section className={className}>
            {img && <Link style={{ boxShadow: `none` }} to={link}><div className={`coverContainer`}><Img sizes={imgSizes} /></div></Link>}
            <small>{date}</small>
            <h3 style={{marginBottom: rhythm(1 / 4)}}>
                <Link style={{ boxShadow: `none` }} to={link}>
                    {title}
                </Link>
            </h3>
            <p dangerouslySetInnerHTML={description}/>
        </section>
    )
}