  
import React, {useContext, useState} from 'react'
import { Link, graphql } from 'gatsby'

import SEO from "../components/seo"
import LayoutMaster from '../components/layout'
import { rhythm } from '../utils/typography'
import {Section} from '../components/sections'
import {GlobalStateContext} from '../context/GlobalContextProvider'
import {Filter, Close} from '../components/icons'
import CardPost from '../components/cardPost'

import section from '../styles/sections.module.css'
import flex from '../styles/flexbox.module.css'
import '../styles/blog.css'

function BlogIndex({pageContext, location, data}) {
    const state = useContext(GlobalStateContext)
    const {menu, tags, prevPage: txtPrevPage, nextPage: txtNextPage, noResults, filter} = state.language
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges
    const [results, setResults] = useState(posts)
    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    const _getUnique = (arr) => [...new Map(arr.map(item => [item.node.fields.slug, item])).values()];

    function _filterResults(e, tagName){
        e.currentTarget.parentNode.classList.add('active')
        let tagFiltered = posts.filter(item=>item.node.frontmatter.tags && item.node.frontmatter.tags.includes(tagName))
        if(posts.length===results.length){
            setResults(tagFiltered)
        }
        else{
            setResults(_getUnique(results.concat(tagFiltered)))
        }
    }

    function _removeTag(tagName){
        let tagFiltered = results.filter(item=>item.node.frontmatter.tags && !item.node.frontmatter.tags.includes(tagName))
        if(tagFiltered.length===0){
            setResults(posts)
        }
        else{
            setResults(tagFiltered)
        }
    }
  
      return (
        <LayoutMaster location={location} title={siteTitle}>
            <SEO title={menu[4].text} />
            <Section className={section.light}>
                <details>
                    <summary className={`${flex.container} ${flex.directionRow} ${flex.smallSpacing} ${flex.alignCenter}`}>
                        <Filter width="30px" height="30px" />
                        <b>{filter}</b>
                    </summary>
                    <div className={`${flex.container} ${flex.spacing} ${flex.wrap} ${flex.alignCenter} tags-area`}>
                        {tags.map((item, index)=>(
                            <p key={index} className={`${flex.container} ${flex.alignCenter}`}>
                                <button onClick={(e)=> _filterResults(e, item)}>{item}</button>
                                <button onClick={(e)=>{
                                    e.currentTarget.parentNode.classList.remove('active')
                                    _removeTag(item)
                                }} >
                                    <Close width="12px" height="12px"/>
                                </button>
                            </p>
                        ))}
                    </div>
                </details>
            {/* <Bio /> */}
                {results.length===0 && <h2>{noResults}</h2>}
                {results.map(({ node }, index) => {
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                        index===0 && 
                        <CardPost 
                            key={node.fields.slug} 
                            className={`${flex.oneThird} postCard first`} 
                            img={node.frontmatter.featuredImage} 
                            imgSizes={node.frontmatter.featuredImage.childImageSharp.sizes} 
                            date={node.frontmatter.date}
                            link={`news${node.fields.slug}`}
                            title={title}
                            description={{
                                __html: node.frontmatter.description || node.excerpt,
                            }}
                        />
                    )
                })}          
                <div style={{ margin: "20px 0 40px" }} className={`${flex.container} ${flex.spacing} ${flex.cols3} ${flex.wrap}`}>
                    {results.map(({ node }, index) => {
                        const title = node.frontmatter.title || node.fields.slug
                        return (
                            index!==0 && 
                            <CardPost 
                                key={node.fields.slug} 
                                className={`${flex.oneThird} postCard ${flex.selfShrink}`}
                                img={node.frontmatter.featuredImage} 
                                imgSizes={node?.frontmatter?.featuredImage?.childImageSharp?.sizes} 
                                date={node.frontmatter.date}
                                link={`news${node.fields.slug}`}
                                title={title}
                                description={{
                                    __html: node.frontmatter.description || node.excerpt,
                                }}
                            />
                        )
                    })}
                </div>
                {posts.length===results.length &&
                    <section
                        className={`${flex.container} ${flex.spacing} ${flex.directionRow} ${flex.justifySpaceBetween}`}
                    >
                        {!isFirst && (
                        <Link to={`/news/${prevPage}`} rel="prev">
                            ← {txtPrevPage}
                        </Link>
                        )}
                        <ul className={`${flex.container} ${flex.spacing} ${flex.directionRow}`}>
                            {Array.from({ length: numPages }, (_, i) => (
                                <li
                                    key={`pagination-number${i + 1}`}
                                    style={{
                                    margin: 0,
                                    }}
                                >
                                    <Link
                                    to={`/news/${i === 0 ? '' : i + 1}`}
                                    style={{
                                        padding: rhythm(1 / 4),
                                        textDecoration: 'none',
                                        color: i + 1 === currentPage ? '#ffffff' : '',
                                        background: i + 1 === currentPage ? '#007acc' : '',
                                    }}
                                    >
                                    {i + 1}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        {!isLast && (
                        <Link to={`/news/${nextPage}`} rel="next">
                            {txtNextPage} →
                        </Link>
                        )}
                    </section>
                }
            </Section>
        </LayoutMaster>
      )
  }
  
export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            path
            date(formatString: "DD MMMM, YYYY")
            title
            description
            tags
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }             
          }
        }
      }
    }
  }
`